body {
  font-family: 'Montserrat', sans-serif;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.gTranslateWrapper #gt_float_wrapper {
  position: relative !important;
  inset: 0 !important;
}

.gTranslateWrapper .gt_float_switcher {
  display: flex;
  flex-direction: column-reverse;
  background: none;
  box-shadow: none;
}

.gTranslateWrapper .gt-current-lang {
  display: flex;
  padding: 5px 6px !important;
  gap: 5px;
  align-items: center;
}

.gTranslateWrapper .gt_float_switcher img {
  width: 25px;
  margin: 0 !important;
}

.gTranslateWrapper span.gt-lang-code {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.gTranslateWrapper .gt_float_switcher-arrow {
  transform: rotate(0deg) !important;
}

.gTranslateWrapper .gt_float_switcher-arrow.gt_arrow_rotate {
  transform: rotate(-180deg) !important;
}

.gTranslateWrapper .gt_options {
  position: absolute !important;
  left: 0px;
  top: 34px;
  background: rgb(255, 255, 255);
  width: 100%;
}

.gTranslateWrapper a.nturl {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  padding: 10px 8px !important;
}

.gTranslateWrapper.gtranslateMobile .gt_float_switcher {
  padding: 16px 24px;
}

.gTranslateWrapper.gtranslateMobile .gt-current-lang {
  gap: 20px;
}

.gTranslateWrapper.gtranslateMobile .gt-lang-code {
  font-size: 20px;
  font-weight: 700;
}

.gTranslateWrapper.gtranslateMobile .gt_options {
  position: relative !important;
  top: 0;
}

.gTranslateWrapper.gtranslateMobile .gt_float_switcher img {
  width: 20px;
}

.gTranslateWrapper.gtranslateMobile a.nturl {
  font-size: 20px;
}

.gTranslateWrapper.gtranslateMobile a.nturl img {
  margin-right: 12px !important;
}
